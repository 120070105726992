.demand {

    width: 100%;
}


   /* Technology card 1 css style */
.demand-card1 {
    width: 300px;
    height: 170px;
    background-color: #596E79;
    transition: background-color 0.3s, transform 0.3s;
    border: #596E79 solid 1px;
    border-radius: 5px;
    position: relative;
    background-image: url('../../images/learncodefaster.jpg');
    background-position: center;
    background-repeat: no-repeat;
  
}

.demand-card1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #596e79d0;
   
}



.demand-card1:hover,
.demand-card.touch-hover {
    background-color: #ffffff;
    transform: scale(1.05);
}


  /* Technology card 2 css style */
  .demand-card2 {
    width: 300px;
    height: 170px;
    background-color: #C7B198;
    transition: background-color 0.3s, transform 0.3s;
    border: #C7B198 solid 1px;
    border-radius: 5px;
    position: relative;
    background-image: url('../../images/designn.jpg');
    background-position: center;
    background-repeat: no-repeat;
  
}

.demand-card2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #c7b198d7;
   
}



.demand-card2:hover,
.demand-card.touch-hover {
    background-color: #ffffff;
    transform: scale(1.05);
}



 /* Technology card 2 css style */
 .demand-card3 {
    width: 300px;
    height: 170px;
    background-color: #C7B198;
    transition: background-color 0.3s, transform 0.3s;
    border: #C7B198 solid 1px;
    border-radius: 5px;
    position: relative;
    background-image: url('../../images/mrk.jpg');
    background-position: center;
    background-repeat: no-repeat;
  
}

.demand-card3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #596e79d0;
   
}



.demand-card3:hover,
.demand-card.touch-hover {
    background-color: #ffffff;
    transform: scale(1.05);
}


/* Technology card 2 css style */
.demand-card4 {
    width: 300px;
    height: 170px;
    background-color: #C7B198;
    transition: background-color 0.3s, transform 0.3s;
    border: #C7B198 solid 1px;
    border-radius: 5px;
    position: relative;
    background-image: url('../../images/nurse.jpg');
    background-position: center;
    background-repeat: no-repeat;
  
}

.demand-card4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #c7b198d7;
   
}


.demand-card4:hover,
.demand-card.touch-hover {
    background-color: #ffffff;
    transform: scale(1.05);
}

/* Technology card 2 css style */
.demand-card6 {
    width: 300px;
    height: 170px;
    background-color: #C7B198;
    transition: background-color 0.3s, transform 0.3s;
    border: #C7B198 solid 1px;
    border-radius: 5px;
    position: relative;
    background-image: url('../../images/finance.jpg');
    background-position: center;
    background-repeat: no-repeat;
  
}

.demand-card6::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #c7b198d7;
   
}


.demand-card6:hover,
.demand-card.touch-hover {
    background-color: #ffffff;
    transform: scale(1.05);
}

/* Technology card 2 css style */
.demand-card4 {
    width: 300px;
    height: 170px;
    background-color: #C7B198;
    transition: background-color 0.3s, transform 0.3s;
    border: #C7B198 solid 1px;
    border-radius: 5px;
    position: relative;
    background-image: url('../../images/nurse.jpg');
    background-position: center;
    background-repeat: no-repeat;
  
}

.demand-card4::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #c7b198d7;
   
}


.demand-card4:hover,
.demand-card.touch-hover {
    background-color: #ffffff;
    transform: scale(1.05);
}

/* Technology card 2 css style */
.demand-card5 {
    width: 300px;
    height: 170px;
    background-color: #596e79d0;
    transition: background-color 0.3s, transform 0.3s;
    border: #596e79d0 solid 1px;
    border-radius: 5px;
    position: relative;
    background-image: url('../../images/farm.jpg');
    background-position: center;
    background-repeat: no-repeat;
  
}

.demand-card5::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #596e79d0;
   
}


.demand-card5:hover,
.demand-card.touch-hover {
    background-color: #ffffff;
    transform: scale(1.05);
}