@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

.custom-class {
  width: 450px;
  height: 320px;
  border-radius: 20px;
}


.joinus {
  border-radius: 20px;
}

@media screen and (max-width: 768px) {
  .custom-class {
    width: 400px;
    height: 250px;
    border-radius: 20px;
  }
}



@layer base {
  body {
   @apply text-[#22588F] bg-slate-50
  }

  /* h1 {
     @apply text-7xl 
  } */

  h2 {
    @apply text-3xl text-[#22588F] text-center md:text-4xl
  }

  h3 {
    @apply text-xl  underline underline-offset-4
  }
  h4 {
    @apply text-base font-normal 
  }
  p {
    @apply text-base text-white
  }

  /* span {
    @apply p-[1px] px-[5px] text-white bg-blue-300 rounded-md 
  } */

  a {
    @apply hover:underline underline-offset-2 font-medium 
  }

  button {
    @apply bg-[#22588F] text-white px-3 py-2 rounded-md hover:duration-300 hover:opacity-80
  }

  form {
    @apply flex flex-col  gap-1 mt-8
  }

  input, textarea {
    @apply p-1 border border-slate-300 rounded-sm outline-none lg:w-1/2   mx-auto w-full
  }

  label {
    @apply lg:w-1/2 mx-auto w-full
  }

}