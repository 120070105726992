body {
  margin: 0;
  padding: 0;
  
}
.home-main {
  position: relative;
  background-image: url("./images/66.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  background-color: white;
}

.home-main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffac; 
  
}


.user-dash-header {
  background: linear-gradient(#596e79ea, #596e79eb), url("./images/about.png");
  background-size: cover; 
  position: relative; 
  background-repeat: no-repeat;
}



.findText{
 line-height: 1.2;
}

.ramadreamsText{
  
}

.contact-container {
  border-radius: 10px
}


/* CSS for the modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: grid;
  place-items: center;
}

.modal h2 {
  font-weight: 700;
  margin-top: 10px;
}

.modal p {
  margin-bottom: 20px;
  color:  #0D4C36;
}

.modal button {
  padding: 8px 16px;
  background-color: #fe0000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #e97638;
}

.doneBtn {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #0D4C36;
  display: grid;
  place-items: center;
}







/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .findText {
    font-size: 30px; /* Adjust the font size for smaller screens */
    text-align: center; 
  }

  .ramadreamsText{
    font-size: 30px; /* Adjust the font size for smaller screens */
    text-align: center; 
    margin-top: 20
  }

  .theOneStopText {
    font-size: 30px; 
  }

 
}

/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
  .findText {
    font-size: 10; /* Adjust the font size for even smaller screens */
    text-align: center; 
  }

  .ramadreamsText{
    font-size: 20px; /* Adjust the font size for smaller screens */
    text-align: center; 
    margin-top: 20
  }

  .theOneStopText {
    font-size: 22px;  
    text-align: left;
  }


}




