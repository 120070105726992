.job-main {
    width: 100%;
    background-color: #f0ece361;
}

.job-card {
    width: 290px;
    height: 380px;
    border: 1px solid #DFD3C3;
    border-radius: 15px;
    padding: 15px;
    background-color:  #ffff;
}